var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form" }, [
    _c("h2", { staticClass: "form__heading" }, [_vm._v("Edit profile")]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form__group" },
          [
            _c("Field", {
              ref: "name",
              attrs: {
                name: "name",
                label: "Name",
                type: "text",
                validation: _vm.required,
              },
              on: {
                input: function ($event) {
                  _vm.userSaved = false
                },
                focus: function ($event) {
                  _vm.userSaved = false
                },
              },
              model: {
                value: _vm.userData.name,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.userData,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "userData.name",
              },
            }),
            _vm._v(" "),
            _c(
              "ImageUploadField",
              {
                ref: "profileImage",
                attrs: {
                  name: "profileImage",
                  label: "Profile Image",
                  disabled: !!_vm.userData.photo,
                },
                on: { change: _vm.uploadProfileImage },
              },
              [
                _vm.userData.photo
                  ? _c(
                      "span",
                      {
                        staticClass: "edit-profile__profile-image",
                        style: {
                          backgroundImage:
                            "url(" +
                            this.$blobUrl +
                            "/images/" +
                            _vm.userData.photo +
                            ")",
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "field__upload-remove-image",
                          domProps: { innerHTML: _vm._s(_vm.svg.close) },
                          on: { click: _vm.removeProfileImage },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.userData.photo
                  ? _c("ImageUploadFieldPlaceholder", {
                      attrs: {
                        loading: _vm.uploadingProfileImage,
                        square: true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.userSaved
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message form__message--success" }, [
                _c("span", {
                  staticClass: "form__message-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.check) },
                }),
                _vm._v("\n        User saved!\n      "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--right" },
          [
            _c("fs-button-main", { attrs: { loading: _vm.loading } }, [
              _vm._v("Save"),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }