<template>
  <section class="form">
    <h2 class="form__heading">Edit profile</h2>
    <form action="#" @submit.prevent="save()">
      <div class="form__group">
        <Field
          name="name"
          ref="name"
          label="Name"
          type="text"
          v-model.trim="userData.name"
          :validation="required"
          @input="userSaved = false"
          @focus="userSaved = false"
        />
        <ImageUploadField
          name="profileImage"
          ref="profileImage"
          label="Profile Image"
          :disabled="!!userData.photo"
          @change="uploadProfileImage"
        >
          <span
            class="edit-profile__profile-image"
            v-if="userData.photo"
            :style="{
              backgroundImage: 'url(' + this.$blobUrl + '/images/' + userData.photo + ')',
            }"
          >
            <span class="field__upload-remove-image" v-html="svg.close" @click="removeProfileImage"></span>
          </span>
          <ImageUploadFieldPlaceholder :loading="uploadingProfileImage" v-if="!userData.photo" :square="true" />
        </ImageUploadField>
      </div>
      <div class="form__group form__group--message" v-if="userSaved">
        <p class="form__message form__message--success">
          <span class="form__message-icon" v-html="svg.check"></span>
          User saved!
        </p>
      </div>
      <div class="form__button-group form__button-group--right">
        <fs-button-main :loading="loading">Save</fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import Field from './../components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import ImageUploadField from './../components/image-upload-field.vue';
import ImageUploadFieldPlaceholder from './../components/image-upload-field-placeholder.vue';

import close from '../../img/baseline-close-24px.svg';
import error from '../../img/baseline-error-24px.svg';
import check from '../../img/baseline-check_circle-24px.svg';

import imageLoader from '../../lib/imageLoader';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Login`;
  },
  components: { Field, FsButtonMain, ImageUploadField, ImageUploadFieldPlaceholder },
  data() {
    return {
      loading: false,
      uploadingProfileImage: false,
      userSaved: false,
      svg: {
        error,
        check,
        close,
      },
      userData: JSON.parse(JSON.stringify(this.$store.user.current)),
    };
  },
  methods: {
    required(val) {
      if (val === '') return 'Required!';
    },
    removeProfileImage() {
      this.$refs.profileImage.clear();
      this.userData.photo = '';
      this.userSaved = false;
    },
    uploadProfileImage(files) {
      this.userSaved = false;
      this.uploadingProfileImage = true;

      this.$store.user
        .uploadProfileImage(files[0])
        .then((image) => {
          return imageLoader(`${this.$blobUrl}/images/${image}`).then(() => {
            this.userData.photo = image;
            this.uploadingProfileImage = false;
          });
        })
        .catch(() => {
          this.uploadProfileImage = false;

          // TODO: Add error handling!
        });
    },
    save() {
      this.loading = true;
      this.unknownError = false;

      Promise.all([this.$refs.name.validates()])
        .then((validations) => {
          if (validations.filter((validation) => !validation).length) {
            this.loading = false;
            return;
          }

          return this.$store.user
            .save(this.userData)
            .then((status) => {
              this.loading = false;
              this.userSaved = true;
            })
            .catch((error) => {
              throw new Error(error);
            });
        })
        .catch((error) => {
          this.unknownError = true;
          this.loading = false;
        });
    },
    decline() {
      if (this.$route.query.declined) {
        this.$notify({
          type: 'error',
          title: 'Permission declined',
        });
      }
    },
  },
  mounted() {
    window.addEventListener('load', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';

.edit-profile {
  &__profile-image {
    display: inline-block;
    background-size: cover;
    background-position: center;
    height: 10.5rem;
    width: 10.5rem;
    margin: 0.5rem 0.25rem 0 0.25rem;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
